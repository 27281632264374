import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const VideoSection = () => {
  const videos = [
    {
      src: 'https://www.youtube.com/embed/2bpP3SMl99Q?si=LmhK5yTlIJhEkxmC', // Git and GitHub 
      title: 'Git and GitHub Course'
    },
    {
      src: 'https://www.youtube.com/embed/2oOan8OnuJ4?si=yWxoL0pNQGdgQdoP', // Node.js Tutorial
      title: 'Node.js Tutorial'
    },
    {
      src: 'https://www.youtube.com/embed/7h1yOon2smw?si=TVeftpW3vK2bTYSq', // MongoDB Tutorial
      title: 'MongoDB Tutorial'
    },
    {
      src: 'https://www.youtube.com/embed/H_nj9M4oztg?si=gUch5hAwNZu5ZCy6', // E-commerce Development 
      title: 'E-commerce Development'
    }
  ];

  const [videoIndex, setVideoIndex] = useState(0);

  // Switch video every 6 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setVideoIndex((prevIndex) =>
        prevIndex === videos.length - 1 ? 0 : prevIndex + 1
      );
    }, 6000); // 6 seconds

    return () => clearInterval(intervalId);
  }, [videos.length]);

  return (
    <div className='w-full h-screen bg-gradient-to-br from-gray-800 to-gray-900 flex flex-col items-center justify-center p-10'>
      {/* Glow line indicating the courses/talks */}
      <div className='absolute top-10 left-0 right-0 h-1 bg-gradient-to-r from-cyan-500 to-blue-500 animate-pulse'></div>

      {/* Heading for Video Section */}
      <p className='text-4xl font-bold inline border-b-4 border-gray-500 mb-4 text-white'>Courses and Talks</p>

      {/* Video Container */}
      <div className='relative w-full max-w-screen-lg'>
        {/* Video Player */}
        <div className='overflow-hidden rounded-2xl shadow-lg transform transition duration-300 hover:scale-105'>
          <iframe
            width='100%'
            height='450px'
            src={videos[videoIndex].src}
            title='YouTube video player'
            frameBorder='0'
            className='rounded-2xl'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </div>

        {/* Video Title */}
        <div className='text-white text-center mt-3'>
          {videos[videoIndex].title}
        </div>

        {/* Navigation Arrows */}
        <div className='absolute top-1/2 left-0 transform -translate-y-1/2'>
          <FaChevronLeft
            className='text-white text-3xl cursor-pointer hover:scale-125 transition-transform duration-300'
            onClick={() =>
              setVideoIndex((prevIndex) =>
                prevIndex === 0 ? videos.length - 1 : prevIndex - 1
              )
            }
          />
        </div>
        <div className='absolute top-1/2 right-0 transform -translate-y-1/2'>
          <FaChevronRight
            className='text-white text-3xl cursor-pointer hover:scale-125 transition-transform duration-300'
            onClick={() =>
              setVideoIndex((prevIndex) =>
                prevIndex === videos.length - 1 ? 0 : prevIndex + 1
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
