 import React from 'react'
import Navbar from './components/Navbar'
import Home from './components/Home'
import SocialLinks from './components/SocialLinks'
import About from './components/About'
import Portfolio from './components/Portfolio'
import Experience from './components/Experience'
import { Contact } from './components/Contact'
import VideoSection from './components/VideoSection';
import Exe from './components/Exe';
import ImgSlider from './components/ImgSlider';

import Logo from './components/Logo'
 
 
 
 const App = () => {
   return (
     <div className='bg-black select-none    overflow-hidden  '>
        <Navbar />
        <Home/> 
        <SocialLinks/>
        <Portfolio/>
        <Experience/>
        <About/>
        <VideoSection/>
        <Exe />
        <Logo />
        <ImgSlider />
        <Contact/>



  
    
     </div>
   )
 }
 
 export default App