import React, { useState, useEffect } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-scroll';

import HeroImage1 from '../image/Profile1.jpg'; // Add the actual path of your images
import HeroImage2 from '../image/odoo.jpeg';
import HeroImage3 from '../image/arya.jpg'; 
import HeroImage4 from '../image/kota.jpg'; 
import HeroImage5 from '../image/skit.jpg'; 

const Home = () => {
  const images = [HeroImage1, HeroImage2, HeroImage3 , HeroImage4 , HeroImage5 ]; // Array of images for the slider
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Automatically change images every 2 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000); // Change image every 2 seconds

    return () => clearInterval(intervalId); // Clean up the interval when the component unmounts
  }, [images.length]);

  return (
    <div name='home' className='lg:h-screen bg-gradient-to-br from-black to-gray-700'>
      <div className='max-w-screen flex flex-col md:flex-row items-center justify-center h-full pt-10 lg:pt-0 lg:pb-0 pb-5 lg:mx-4 md:px-10'>
        <div className='flex flex-col lg:ml-5 justify-center mx-auto lg:w-[100%] w-[75%] h-full mt-16 gap-y-5'>
          <h1 className='text-white text-4xl lg:text-7xl lg:-ml-0 -ml-6 font-bold'> Hello! </h1>
          <p className='text-gray-400 text-xl py-4 lg:max-w-md ml-2 text-justify tracking-tighter'>
            I'm a MERN Stack Developer (1 year) specializing in React, Node.js, Express.js, and MongoDB. Passionate about creating content, speaking at conferences, and organizing workshops & events. 💻🎤✨
          </p>

          <Link to='projects' smooth duration={500}>
            <button className='flex gap-2 items-center text-white font-semibold px-6 py-3 my-3 rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer group'>
              Portfolio
              <FaChevronRight className='group-hover:rotate-90 duration-300' />
            </button>
          </Link>
        </div>

        {/* Image slider */}
        <div className='mt-2'>
          <img
            src={images[currentImageIndex]}
            alt='Profile is missing'
            className='lg:w-[500px] w-[270px] rounded-2xl'
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
