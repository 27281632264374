// Logo.js
import React from 'react';
import './Css/Logo.css'; // Import CSS for styling

// Import logos
import logo1 from '../image/logo/2.png';
import logo2 from '../image/logo/3.png';
import logo3 from '../image/logo/4.png';
import logo4 from '../image/logo/5.png';
import logo5 from '../image/logo/6.png';
import logo6 from '../image/logo/7.png';
import logo7 from '../image/logo/8.png';

// Update the logos array to use the imported images
const logos = [
  { src: logo1, alt: 'Partner 1' },
  { src: logo2, alt: 'Partner 2' },
  { src: logo3, alt: 'Partner 3' },
  { src: logo4, alt: 'Partner 4' },
  { src: logo5, alt: 'Partner 5' },
  { src: logo6, alt: 'Partner 6' },
  { src: logo7, alt: 'Partner 7' },
];

const Logo = () => {
  return (
    <div className="slider-container bg-gradient-to-br from-black to-gray-700 text-white p-4">
      <h2 className='text-4xl font-bold border-b-4 rounded-2xl border-gray-500 p-2 inline text-white'>Work With </h2>
      <div className="slider">
        <div className="logo-container">
          {logos.map((logo, index) => (
            <img key={index} src={logo.src} alt={logo.alt} className="logo" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Logo;
