import React, { useState } from 'react';
import './Css/exe.css';

// Component for the experience
function Experience({ title, description, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="experience">
      <h2 onClick={() => setIsOpen(!isOpen)}>{title}</h2>
      {isOpen && (
        <div className="experience-content">
          <p>{description}</p>
          {children}
        </div>
      )}
    </div>
  );
}

// Component for the 3D Product Catalog experience
function ThreeDProductCatalog() {
  return (
    <Experience title="3D Product Catalog" description="Revolutionize the way you showcase your products online. Bring your catalog to life in a 360 degree stunning 3D display for an unbeatable customer experience.">
      <button>Book a Demo</button>
      <img src="image-url" alt="Image" />
      <button>Chat with us</button>
    </Experience>
  );
}

// Component for the Pop-up Stores experience
function PopUpStores() {
  return (
    <Experience title="Pop-up Stores" description="Launching a new product, promoting an event, or seeking to engage your audience? Our virtual pop-ups are designed to captivate, inspire, and drive action.">
      <button>Book a Demo</button>
      <img src="image-url" alt="Image" />
      <button>Chat with us</button>
    </Experience>
  );
}

// Component for the Digital Twins experience
function DigitalTwins() {
  return (
    <Experience title="Digital Twins" description="Unlock the power of digital transformation with cutting-edge digital twins. Empowering businesses to optimize operations, enhance decision-making, and drive innovation.">
      <button>Book a Demo</button>
      <img src="image-url" alt="Image" />
      <button>Chat with us</button>
    </Experience>
  );
}

// Main App component
function Exe() {
  return (
    <div className="app">
      <ThreeDProductCatalog />
      <PopUpStores />
      <DigitalTwins />
    </div>
  );
}

export default Exe;