import React, { useState, useEffect } from "react";
import "./Css/ImgSlider.css"; // Ensure this file path is correct

const imagesContainer1 = [
  require("../image/left/1.png"),
  require("../image/left/2.png"),
  require("../image/left/3.png"),
  require("../image/left/4.png"),
  require("../image/left/5.png"),
  require("../image/left/6.png"),
  require("../image/left/7.png"),
  require("../image/left/8.png"),
  require("../image/left/9.png"),
];

const imagesContainer2 = [
  require("../image/right/1.png"),
  require("../image/right/2.png"),
  require("../image/right/3.png"),
  require("../image/right/4.png"),
  require("../image/right/5.png"),
  require("../image/right/4.png"),
  require("../image/right/2.png"),
  require("../image/right/3.png"),
  require("../image/right/5.png"),
];

const ImgSlider = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % imagesContainer1.length);
    }, 2000); // Change image every 2 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="main-container">
      {/* Container for sliders */}
      <div className="container">
        {/* Left-side images */}
        <div className="sub-container left">
          <img src={imagesContainer1[index]} alt="Left Slider 1" />
          <img src={imagesContainer1[(index + 1) % imagesContainer1.length]} alt="Left Slider 2" />
        </div>

        {/* Right-side image */}
        <div className="sub-container right">
          <img src={imagesContainer2[index]} alt="Right Slider" />
        </div>
      </div>
    </div>
  );
};

export default ImgSlider;
